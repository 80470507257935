<!--
  Component to display the found OMW link
-->
<template>
  <!-- <div style="width: 95vw"> -->
  <iframe width="100%" height="1000px" :src="url" />
  <!-- </div> -->
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'OmwLookupResult',
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      demo: this.$configData.omwLookup.demo,
      demoUrl: this.$configData.omwLookup.demoUrl,
    };
  },
});
</script>
